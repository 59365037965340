import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { ROLES } from '../../../../constants/common';

import './styles.css';

const UserForm = ({ show, handleClose, handleSave, saveError, orgList, editItem }) => {
  const storeJwt = useSelector((state) => state.session.jwt);
  const currentUser = jwtDecode(storeJwt);
  const [validated, setValidated] = useState(false);
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [institution, setInstitution] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [role, setRole] = useState('');
  const [organization, setOrganization] = useState([]);

  const availableRoles = useMemo(() => {
    if (currentUser.role === 'SUPER_ADMIN') {
      return ROLES;
    }
    if (currentUser.role === 'ORGANIZATION_ADMIN') {
      return ['ORGANIZATION_ADMIN', 'NATIONAL_USER'];
    }
    return ['ADMIN', 'NATIONAL_USER'];
  }, [currentUser]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (
      form.checkValidity() === false ||
      ((role === 'ORGANIZATION_ADMIN' || currentUser.role === 'ORGANIZATION_ADMIN') &&
        organization.length === 0)
    ) {
      setValidated(true);
    } else {
      handleSave({
        username,
        email: username,
        firstName,
        lastName,
        status: 'ACTIVE',
        role,
        state: '',
        organization,
        lastLogin: new Date(),
        institution,
      });
    }
  };

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handleFirstNameChange = (e) => setFirstName(e.target.value);
  const handleLastNameChange = (e) => setLastName(e.target.value);
  const handleRoleChange = (e) => setRole(e.target.value);
  const handleInstitutionChange = (e) => setInstitution(e.target.value);
  const handleOrganizationChange = (items) => {
    setOrganization(items.map((i) => i.value));
  };

  useEffect(() => {
    if (!editItem && !show) return;

    setUsername(editItem.email);
    setFirstName(editItem.firstName);
    setLastName(editItem.lastName);
    setRole(editItem.role);
    setInstitution(editItem.institution);
    if (editItem.organizations.length > 0) {
      const currentOrganizations = editItem.organizations?.filter(
        (org) =>
          editItem.role === 'SUPER_ADMIN' ||
          editItem.role === 'ADMIN' ||
          orgList.findIndex((o) => o.id === org.id) >= 0
      );
      setOrganization(currentOrganizations.map((i) => i.id));
      setSelectedOrganization(
        currentOrganizations.map((org) => ({
          label: org.name,
          value: org.id,
        }))
      );
    }
  }, [editItem, show, orgList]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>{editItem?.email ? 'Edit User' : 'Create User'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {saveError && (
            <Alert variant="danger">
              <p>
                {saveError}
                {currentUser.role === 'ORGANIZATION_ADMIN'
                  ? ' Contact your Administrator to add them to your organization.'
                  : ''}
              </p>
            </Alert>
          )}
          <Form noValidate validated={validated} className="text-left" onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="validationUsername">
                <Form.Label>Username *</Form.Label>
                <Form.Control
                  defaultValue={username}
                  placeholder="user@email.com"
                  required
                  type="email"
                  onChange={handleUsernameChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please complete username.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="validationFirstName">
                <Form.Label>First Name *</Form.Label>
                <Form.Control
                  defaultValue={firstName}
                  placeholder="John"
                  required
                  type="text"
                  onChange={handleFirstNameChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please complete First Name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationLastName">
                <Form.Label>Last Name *</Form.Label>
                <Form.Control
                  defaultValue={lastName}
                  placeholder="Doe"
                  required
                  type="text"
                  onChange={handleLastNameChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please complete Last Name.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationRole">
                <Form.Label>Role *</Form.Label>
                <Form.Control as="select" required onChange={handleRoleChange}>
                  <option value="">Role</option>
                  {availableRoles.map((r) => (
                    <option key={r} value={r} selected={role === r}>
                      {r}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">Please select a role.</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                md="6"
                className={
                  (role === 'ORGANIZATION_ADMIN' || currentUser.role === 'ORGANIZATION_ADMIN') &&
                  organization.length === 0 &&
                  validated
                    ? 'form-invalid'
                    : ''
                }
                controlId="validationOrganization"
              >
                <Form.Label>
                  {role === 'ORGANIZATION_ADMIN' || currentUser.role === 'ORGANIZATION_ADMIN'
                    ? 'Organization *'
                    : 'Organization'}
                </Form.Label>
                {selectedOrganization && (
                  <Select
                    defaultValue={selectedOrganization}
                    placeholder="Select Organization"
                    options={orgList.map((l) => ({
                      label: l.name,
                      value: l.id,
                    }))}
                    isMulti
                    isClearable={!editItem?.email}
                    closeMenuOnSelect={false}
                    onChange={handleOrganizationChange}
                    classNamePrefix="react-select-org"
                  />
                )}
                {(role === 'ORGANIZATION_ADMIN' || currentUser.role === 'ORGANIZATION_ADMIN') &&
                  organization.length === 0 &&
                  validated && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      Please select at least one organization.
                    </Form.Control.Feedback>
                  )}
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationOrganization">
                <Form.Label>Institution</Form.Label>
                <Form.Control
                  placeholder="Institution"
                  type="text"
                  onChange={handleInstitutionChange}
                  defaultValue={institution}
                />
              </Form.Group>
            </Form.Row>
            <Row>
              <Col>
                <hr />
                <Button
                  variant="outline-secondary"
                  className="btn btn-custom white mr-3"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button variant="primary" type="submit" className="btn btn-custom dark">
                  Save changes
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};

UserForm.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  saveError: PropTypes.string,
  orgList: PropTypes.arrayOf({}),
  editItem: PropTypes.shape({
    email: '',
    firstName: '',
    lastName: '',
    role: '',
    institution: '',
    organizations: [],
  }).isRequired,
};

UserForm.defaultProps = {
  saveError: undefined,
  orgList: {},
};

export default UserForm;
