/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faCalculator,
  faExternalLinkAlt,
  faTint,
  faSortDown,
  faInfoCircle,
  faFileAlt,
  faForward,
} from '@fortawesome/free-solid-svg-icons';
import shortid from 'shortid';
import CalculateMoeConfirmationModal from '../CalculateMoeConfirmationModal';
import MoeCalculationInProgressModal from '../MoeCalculationInProgressModal';
import './styles.css';

const actionButtonDelay = { show: 1000, hide: 200 };

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <span
    className="cursor-pointer"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <FontAwesomeIcon size="lg" className="ml-5" icon={faSortDown} />
  </span>
));

CustomToggle.displayName = 'CustomToggle';

const SurveyHeader = ({
  surveyDetails,
  loadNewSectionData,
  selectedSectionIndex,
  onExportButtonClick,
  handleCalculateMoeModalConfirm,
  onAddToReportButtonClick,
  onStyleSelectorButtonClick,
  onExpandAll,
  isExpandedAll,
}) => {
  const [showCalculateMoeModal, setShowCalculateMoeModal] = useState(false);
  const handlePrevSection = () => {
    loadNewSectionData(selectedSectionIndex - 1);
  };

  const handleNextSection = () => {
    loadNewSectionData(selectedSectionIndex + 1);
  };

  if (!surveyDetails || !surveyDetails.questionSections) {
    return null;
  }

  const handleConfirm = () => {
    setShowCalculateMoeModal(false);
    handleCalculateMoeModalConfirm();
  };

  return (
    <Row noGutters className="p-3 text-white bg-blue-400 d-flex align-items-center">
      <Col md="3">
        <Link className="text-white text-reset" to="/survey-explorer">
          Survey Explorer
        </Link>
        {' > '}
        {surveyDetails?.name}
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip-survey-description">
              <h4 className="text-left">Content Description:</h4>
              <div className="my-2 text-left">
                {!surveyDetails?.surveySummaryDetails?.description
                  ? '-'
                  : surveyDetails?.surveySummaryDetails?.description}
              </div>
              <h4 className="text-left">Administered:</h4>
              <div className="my-2 text-left">
                {!surveyDetails?.surveySummaryDetails?.dateAdministrated
                  ? '-'
                  : surveyDetails?.surveySummaryDetails?.dateAdministrated}
              </div>
            </Tooltip>
          }
        >
          <FontAwesomeIcon size="lg" className="ml-2" icon={faInfoCircle} />
        </OverlayTrigger>
      </Col>
      <Col md="6" className="d-flex justify-content-center align-items-center">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="prev-sec">Previous Section</Tooltip>}
          delay={200}
        >
          <FontAwesomeIcon
            className="cursor-pointer"
            disabled={selectedSectionIndex === 0}
            onClick={handlePrevSection}
            icon={faChevronCircleLeft}
            size="lg"
          />
        </OverlayTrigger>
        <Dropdown className="w-50 text-center mx-5 font-size-24">
          <Dropdown.Toggle className="w-100 d-flex" as={CustomToggle}>
            {surveyDetails?.questionSections[selectedSectionIndex].name}
          </Dropdown.Toggle>

          <Dropdown.Menu className="bg-dark">
            {surveyDetails?.questionSections?.map((qs, index) => (
              <Dropdown.Item
                className="text-white"
                key={shortid.generate()}
                onClick={() => loadNewSectionData(index)}
              >
                {qs.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="next-sec">Next Section</Tooltip>}
          delay={200}
        >
          <FontAwesomeIcon
            className="cursor-pointer"
            size="lg"
            onClick={handleNextSection}
            icon={faChevronCircleRight}
          />
        </OverlayTrigger>
      </Col>
      <Col md="3" className="text-right">
        <OverlayTrigger overlay={<Tooltip>Export all questions</Tooltip>} delay={actionButtonDelay}>
          <FontAwesomeIcon
            className="mr-5 cursor-pointer"
            icon={faExternalLinkAlt}
            size="lg"
            onClick={onExportButtonClick}
          />
        </OverlayTrigger>
        <OverlayTrigger
          overlay={<Tooltip>Add all questions to report</Tooltip>}
          delay={actionButtonDelay}
        >
          <FontAwesomeIcon
            className="mr-5 cursor-pointer"
            icon={faFileAlt}
            size="lg"
            onClick={onAddToReportButtonClick}
          />
        </OverlayTrigger>
        <OverlayTrigger
          overlay={<Tooltip>Calculate margin of error</Tooltip>}
          delay={actionButtonDelay}
        >
          <FontAwesomeIcon
            className="mr-5 cursor-pointer"
            icon={faCalculator}
            size="lg"
            onClick={() => setShowCalculateMoeModal(true)}
          />
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>Change style</Tooltip>} delay={actionButtonDelay}>
          <FontAwesomeIcon
            className="mr-5 cursor-pointer"
            icon={faTint}
            size="lg"
            onClick={onStyleSelectorButtonClick}
          />
        </OverlayTrigger>
        <OverlayTrigger
          overlay={<Tooltip>{isExpandedAll ? 'Collapse' : 'Expand'} all</Tooltip>}
          delay={actionButtonDelay}
        >
          <FontAwesomeIcon
            className={`mr-5 cursor-pointer ${isExpandedAll ? 'collapsed-all' : 'expanded-all'}`}
            icon={faForward}
            size="lg"
            onClick={onExpandAll}
          />
        </OverlayTrigger>

        <CalculateMoeConfirmationModal
          show={showCalculateMoeModal}
          onConfirm={handleConfirm}
          onClose={() => setShowCalculateMoeModal(false)}
        />
        <MoeCalculationInProgressModal />
      </Col>
    </Row>
  );
};

SurveyHeader.propTypes = {
  surveyDetails: PropTypes.shape({
    questionSections: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string,
  }).isRequired,
  loadNewSectionData: PropTypes.func.isRequired,
  selectedSectionIndex: PropTypes.number.isRequired,
  onExpandAll: PropTypes.func.isRequired,
  isExpandedAll: PropTypes.bool.isRequired,
  handleCalculateMoeModalConfirm: PropTypes.func.isRequired,
};

export default SurveyHeader;
