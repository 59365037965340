import types from '../types';

const initialState = {
  activeSection: {},
  allSections: [],
  isLoading: false,
};

const sectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.SECTION_LIST_PENDING: {
      return {
        ...state,
        isLoading: true,
        allSections: [],
      };
    }
    case types.reducerTypes.SECTION_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allSections: action.payload,
      };
    }
    case types.reducerTypes.SECTION_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        allSections: [],
      };
    }
    case types.reducerTypes.SECTION_ID_PENDING: {
      return {
        ...state,
        isLoading: true,
        activeSection: {},
      };
    }
    case types.reducerTypes.SECTION_ID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        activeSection: action.payload,
      };
    }
    case types.reducerTypes.SECTION_ID_ERROR: {
      return {
        ...state,
        isLoading: false,
        activeSection: {},
      };
    }
    case types.reducerTypes.SECTION_UPDATE_PENDING: {
      return {
        ...state,
        isLoading: true,
        sectionUpdated: false,
      };
    }
    case types.reducerTypes.SECTION_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: true,
        sectionUpdated: true,
      };
    }
    default:
  }
  return state;
};

export default sectionReducer;
