/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import React, { forwardRef, useEffect, useState } from 'react';
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap';
import shortid from 'shortid';

import { HIGHCHARTS_TYPES } from '../../../constants/charts';
import { calculateColumnChartHeight, makeMLITooltip } from '../../../helpers/charts';

const ColumnChart = forwardRef(
  (
    {
      parsedData,
      currentJob = {},
      benchmarks = {},
      colorScheme = 0,
      chartType = HIGHCHARTS_TYPES.COLUMN,
      qtyOptions,
      chartLoaded,
    },
    chartRef
  ) => {
    const [chartOptions, setChartOptions] = useState();
    const [loadingChart, setLoadingChart] = useState(true);

    function xAxisFormatter() {
      if (
        this.value.length > 15 &&
        !this.value.includes(' ') &&
        chartType === HIGHCHARTS_TYPES.COLUMN
      ) {
        return `${this.value.substring(0, 15)}.`;
      }
      return this.value;
    }
    function tooltipFormatter() {
      let text_color;

      let benchmarks_active = false;

      if (parsedData.segmentsActive && (benchmarks.state || benchmarks.national)) {
        benchmarks_active = true;
      }
      let text;
      let percent_diff;
      const benchmark = this.point.benchmark_value;
      let benchmark_label = this.point.benchmark_label;
      const this_category = this.series.name;
      let nat_category = false;
      const { filtered, segmented } = this.point;
      const benchmarked = benchmarks_active;
      const moe = this.point.MOE;

      if (segmented) {
        if (benchmarked) {
          if (this_category.includes('National')) {
            nat_category = true;
          } else if (!this_category.includes('State') && !filtered) {
            nat_category = true;
          }
        } else if (this_category.includes('National')) {
          nat_category = true;
        } else if (
          (this_category.includes('All respondents') && !filtered) ||
          (this_category.includes('deafult') && !filtered)
        ) {
          nat_category = true;
        }
      } else if (!filtered) {
        nat_category = true;
      }

      if (benchmark !== undefined && benchmark !== null && !nat_category) {
        percent_diff = Math.round(this.y - benchmark);

        if (percent_diff >= 0) {
          text_color = '#6f9c2c';
          text = 'greater than';
        } else {
          text_color = '#ac193c';
          text = 'less than';
          percent_diff = Math.abs(percent_diff);
        }
      } else {
        percent_diff = 'NA';
        text_color = '#cbd0d5';
        text = '';
        benchmark_label = '';
      }

      return makeMLITooltip(
        this.x,
        this.y,
        percent_diff,
        moe,
        text,
        benchmark_label,
        this.color,
        text_color,
        false,
        true,
        '',
        ['OPEN_NUMERIC', 'OPEN_TEXT'].includes(currentJob.questionType) ? '' : '%'
      );
    }

    function getSegmentNames(segmentName, i) {
      switch (segmentName) {
        case 'default':
          return 'All respondents';
        case 'nat-av':
          return `National (N=${parsedData.nSize[i]})`;
        case 'state-av':
          return `State (N=${parsedData.nSize[i]})`;
        default:
          return segmentName;
      }
    }

    useEffect(() => {
      if (!currentJob?.queryResult?.suppressed) {
        let margin_left = chartType === 'column' ? 50 : 300;
        if (currentJob.questionType === 'OPEN_TEXT') {
          margin_left = 300;
        }
        const series_colors_map = [
          [
            '#416788',
            '#C17ED4',
            '#9BC53D',
            '#AFD6FE',
            '#7B668D',
            '#81D2C7',
            '#855A5C',
            '#5BC0EB',
            '#C17ED4',
            '#5398BE',
            '#9BC53D',
            '#E55934',
            '#416788',
            '#E4959E',
          ],
          [
            '#4C934C',
            '#E6D246',
            '#9EA8D5',
            '#DEA54B',
            '#A93448',
            '#81D2C7',
            '#855A5C',
            '#5BC0EB',
            '#C17ED4',
            '#5398BE',
            '#9BC53D',
            '#E55934',
            '#416788',
            '#E4959E',
          ],
          [
            '#6f62AF',
            '#EE7869',
            '#7B668D',
            '#9EA8D5',
            '#9BC53D',
            '#81D2C7',
            '#855A5C',
            '#5BC0EB',
            '#C17ED4',
            '#5398BE',
            '#9BC53D',
            '#E55934',
            '#416788',
            '#E4959E',
          ],
          [
            '#45837B',
            '#E6D246',
            '#A93448',
            '#5387BE',
            '#ABD2BF',
            '#81D2C7',
            '#855A5C',
            '#5BC0EB',
            '#C17ED4',
            '#5398BE',
            '#9BC53D',
            '#E55934',
            '#416788',
            '#E4959E',
          ],
        ];

        const legend = {
          layout: 'horizontal',
          x: margin_left,
          margin: 10,
          padding: 0,
          symbolRadius: 0,
          reversed: false,
          itemStyle: {
            fontSize: '14px',
            color: '#606060',
          },
          itemHoverStyle: {
            color: '#606060',
          },
          itemHiddenStyle: {
            color: '#606060',
          },
          itemDistance: 10,
          align: 'left',
          verticalAlign: 'top',
        };

        let xaxis_categories = parsedData.categories;
        const all_series = [];
        parsedData.dataSeries.forEach((dataSeries, dataSeriesIndex) => {
          const seriesName = getSegmentNames(
            parsedData.segmentNames[dataSeriesIndex],
            dataSeriesIndex
          );
          const seriesColor = series_colors_map[colorScheme][dataSeriesIndex];

          // search if there are comparison or positive series present
          let positiveSeries;
          const comparisonSeries = [];
          dataSeries.forEach((el, index) => {
            if (el.name === 'Yes') {
              positiveSeries = el;
            } else if (el.option_type === 'COMPARISON' && (index === 0 || index === 1)) {
              // we could support more than two options if there is a way to identify which series are positive answers
              comparisonSeries.push(el);
            }
          });

          let seriesData; // add only this series for the current segment
          // it's not possible to show both positive and comparison series, so choose one in case both are present
          if (comparisonSeries.length > 0) {
            // add an empty data series to insert a space between comparisons groups
            comparisonSeries.push({
              data: new Array(comparisonSeries[0].data.length).fill(null),
              MOE: new Array(comparisonSeries[0].MOE.length).fill(null),
              benchmark_array: new Array(comparisonSeries[0].benchmark_array.length).fill(null),
              benchmark_labels: new Array(comparisonSeries[0].benchmark_labels.length).fill(''),
              name: '',
              emptySeries: true, // something to identify this series
            });

            if (currentJob?.answers) {
              // rewrite x axis categories with each option prompt
              xaxis_categories = currentJob?.answers?.flatMap((answer) => {
                const answerOptions = answer.answerOptionsType.answerOptions;
                return comparisonSeries.map((series, seriesIndex) => {
                  return seriesIndex < answerOptions.length && !series.emptySeries
                    ? answerOptions[seriesIndex].prompt
                    : '';
                });
              });
            }

            // merge data arrays from all comparison series into a single one
            const data = _.flatten(_.zip(..._.map(comparisonSeries, 'data')));
            const MOE = _.flatten(_.zip(..._.map(comparisonSeries, 'MOE')));
            const benchmark_labels = _.flatten(
              _.zip(..._.map(comparisonSeries, 'benchmark_labels'))
            );
            const benchmark_array = _.flatten(_.zip(..._.map(comparisonSeries, 'benchmark_array')));
            const names = _.map(comparisonSeries, 'name');

            seriesData = _.map(_.range(data.length), (i) => {
              return {
                y: data[i],
                MOE: MOE[i],
                benchmark_label: benchmark_labels[i],
                benchmark_value: benchmark_array[i],
                name: names[i % names.length],
                filtered: comparisonSeries[0].filtered,
                segmented: comparisonSeries[0].segmented,
              };
            });
          } else {
            seriesData = _.map(_.range(positiveSeries ? positiveSeries.data.length : 0), (i) => {
              return {
                y: positiveSeries.data[i],
                MOE: positiveSeries.MOE[i],
                benchmark_label: positiveSeries.benchmark_labels[i],
                benchmark_value: positiveSeries.benchmark_array[i],
                name: positiveSeries.name,
                filtered: positiveSeries.filtered,
                segmented: positiveSeries.segmented,
              };
            });
          }

          if (
            ['OPEN_NUMERIC', 'OPEN_TEXT'].includes(currentJob.questionType) &&
            seriesData &&
            seriesData?.length > 0
          ) {
            const xaxisWithValues = xaxis_categories
              .map((cat, index) => ({
                cat,
                value: seriesData[index].y,
              }))
              .sort((a, b) => b.value - a.value);
            xaxis_categories = xaxisWithValues.map((xaxis) => xaxis.cat);
          }

          if (seriesData) {
            all_series.push({
              data: seriesData,
              name: seriesName,
              color: seriesColor,
            });
          }
        });
        const plot_options = {
          series: {
            turboThreshold: 0,
            groupPadding: 0.12,
            pointPadding: 0.007,
            clip: false,
            bar: {
              clip: false,
            },
            dataLabels: {
              x: chartType === 'column' ? 0 : 14.5,
              enabled: true,
              align: 'center',
              overflow: 'none',
              crop: false,
              shadow: false,
              padding: 0,
              style: {
                color: '#121111',
                fontSize: '14px',
                textShadow: '0px',
                textOutline: false,
                opacity: '0.7',
              },
              format: ['OPEN_NUMERIC', 'OPEN_TEXT'].includes(currentJob.questionType)
                ? '{y}'
                : '{y}%',
            },
          },
          column: {
            events: {
              legendItemClick: () => {
                return false;
              },
            },
            borderWidth: 0,
          },
          bar: {
            clip: false,
          },
        };

        if (['OPEN_NUMERIC', 'OPEN_TEXT'].includes(currentJob.questionType)) {
          plot_options.series.dataSorting = {
            enabled: true,
          };
        }

        const tooltip = {
          backgroundColor: 'rgba(255,255,255,1)',
          style: {
            color: '#343434',
            fontWeight: 'normal',
          },
          useHTML: true,
          padding: 0,
          formatter: tooltipFormatter,
        };

        const chartHeight =
          chartType === HIGHCHARTS_TYPES.BAR ? calculateColumnChartHeight(qtyOptions) : 0;
        const default_theme = {
          chart: {
            type: chartType,
            marginLeft: margin_left,
            marginTop: 50,
            marginRight: 50,
            height: chartHeight < 400 ? 400 : chartHeight,
            spacingLeft: 60,

            // backgroundColor option is static
            backgroundColor: null,

            // Fonts are currently in a shared Dropbox folder (and also in the dev directory)
            style: {
              fontFamily: 'AvenirNext-Regular',
              fontSize: '14px',
            },

            // plotBorderColor option is static
            plotBorderColor: '#f7f7f7',
            events: {
              load() {
                chartLoaded();
              },
            },
          },

          // title formatting options will be static
          title: {
            align: 'left',
            style: {
              fontSize: '14px',
              color: '#808080',
              textShadow: '0px',
              textOutline: false,
            },
            text: null,
          },
          credits: { enabled: false },
          navigation: {
            activeColor: '#ac193c',
            buttonOptions: {
              enabled: false,
            },
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
          tooltip,
          plotOptions: plot_options,
          series: all_series,
          // xAxis formatting will be static
          xAxis: {
            categories: xaxis_categories,
            gridLineColor: '#b8b4b4',
            labels: {
              step: 1,
              padding: 0,
              style: {
                color: '#121111',
                fontSize: '14px',
              },
              formatter: xAxisFormatter,
            },
            lineColor: '#b8b4b4',
            minorGridLineColor: '#b8b4b4',
            tickColor: '#f7f7f7',
            tickInterval: 1,
            title: {
              style: {
                color: '#121111',
                fontSize: '14px',
              },
            },
          },

          // yAxis formatting will be static
          yAxis: {
            visible: false,
            gridLineColor: 'transparent',
            labels: {
              format: '{value}%',
              style: {
                color: '#bdbdbd',
                fontSize: '14px',
              },
            },
            ...(currentJob.questionType !== 'OPEN_TEXT' && { max: 100 }),
            lineColor: '#b8b4b4',
            lineWidth: 1,
            minorGridLineColor: 'transparent',
            tickColor: '#f7f7f7',
            tickWidth: 1,
            title: {
              text: null,
            },
          },
          legend,
        };
        // Deep merge of options
        setChartOptions(default_theme);
      } else {
        setChartOptions({ suppressed: true });
        chartLoaded();
      }
      setLoadingChart(false);
    }, [parsedData]);

    if (loadingChart) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
      );
    }

    if (chartOptions.suppressed) {
      return (
        <Alert className="m-3" variant="warning" key={shortid.generate()}>
          <FontAwesomeIcon icon={faExclamationTriangle} color="red" className="mr-3" /> Data
          visualization suppressed
        </Alert>
      );
    }
    return (
      <HighchartsReact
        key={shortid.generate()}
        highcharts={Highcharts}
        options={chartOptions}
        ref={chartRef}
      />
    );
  }
);

ColumnChart.displayName = 'ColumnChart';

export default React.memo(ColumnChart);
