import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';

import './styles.css';

const SurveyInformationSection = ({ sectionName, onEditModeClick }) => {
  return (
    <Card className="survey-info-card" onClick={() => onEditModeClick(null, 'sections', true)}>
      <Card.Body>
        <h4 className="mb-2">Section Name</h4>
        <span>{sectionName}</span>
      </Card.Body>
    </Card>
  );
};

SurveyInformationSection.propTypes = {
  sectionName: PropTypes.string.isRequired,
  onEditModeClick: PropTypes.func.isRequired,
};

SurveyInformationSection.defaultProps = {};

export default SurveyInformationSection;
