import types from '../types';

const initialState = {
  sampleUpdated: false,
  sample: undefined,
  dimensions: undefined,
  loading: false,
  autoSaving: false,
  autoSaved: true,
  sampleTheme: undefined,
  error: null,
  updateError: null,
};

const surveySample = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.SURVEY_SAMPLE_DIMENSION_GET_PENDING: {
      return {
        ...state,
        sample: undefined,
        dimensions: undefined,
        sampleUpdated: false,
        sampleTheme: undefined,
        loading: true,
        error: null,
      };
    }
    case types.reducerTypes.SURVEY_SAMPLE_DIMENSION_GET_SUCCESS: {
      return {
        ...state,
        sample: action.payload.sample,
        dimensions: action.payload.dimensions,
        sampleTheme: action.payload.sampleTheme,
        loading: false,
        error: null,
      };
    }
    case types.reducerTypes.SURVEY_SAMPLE_DIMENSION_GET_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case types.reducerTypes.SURVEY_SAMPLE_DIMENSION_UPDATE_PENDING: {
      return {
        ...state,
        sampleUpdated: false,
        loading: true,
        updateError: null,
      };
    }
    case types.reducerTypes.SURVEY_SAMPLE_DIMENSION_UPDATE_SUCCESS: {
      return {
        ...state,
        sampleUpdated: true,
        loading: false,
        updateError: null,
      };
    }
    case types.reducerTypes.SURVEY_SAMPLE_DIMENSION_UPDATE_ERROR: {
      return {
        ...state,
        sampleUpdated: false,
        updateError: action.payload,
        loading: false,
      };
    }
    case types.reducerTypes.SURVEY_SAMPLE_DIMENSION_AUTO_SAVING: {
      return {
        ...state,
        autoSaving: true,
        autoSaved: false,
      };
    }
    case types.reducerTypes.SURVEY_SAMPLE_DIMENSION_AUTO_SAVED: {
      return {
        ...state,
        autoSaving: false,
        autoSaved: true,
      };
    }
    default:
      return state;
  }
};

export default surveySample;
