import axios from 'axios';
import { getHeaders } from '../actions/helper.action';
import { API_ROOT } from '../config/appConfig';

export const getSurveySampleByIdApi = async (sampleId, jwt) => {
  const res = await axios.get(`${API_ROOT}/admin-backend/sample/${sampleId}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    return res.data;
  }
  return null;
};

export const updateSurveySampleByIdApi = async (sampleId, data, jwt) => {
  await axios.patch(
    `${API_ROOT}/admin-backend/sample/${sampleId}`,
    data,
    getHeaders('application/json', jwt)
  );
};

export const getSurveySampleDimensionsByIdApi = async (sampleId, jwt) => {
  const res = await axios.get(`${API_ROOT}/admin-backend/sample/${sampleId}/dimensions`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    return res.data;
  }
  return null;
};

export const updateSurveyDimensionByIdApi = async (sampleId, data, jwt) => {
  await axios.patch(
    `${API_ROOT}/admin-backend/dimension/${sampleId}`,
    data,
    getHeaders('application/json', jwt)
  );
};
