import {
  getSurveySampleByIdApi,
  updateSurveySampleByIdApi,
  updateSurveyDimensionByIdApi,
  getSurveySampleDimensionsByIdApi,
} from '../api/survey.sample';
import types from '../types';

export const getSurveySampleById = (id, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_DIMENSION_GET_PENDING });
    const responses = await Promise.all([
      getSurveySampleByIdApi(id, jwt),
      getSurveySampleDimensionsByIdApi(id, jwt),
    ]);
    dispatch({
      type: types.reducerTypes.SURVEY_SAMPLE_DIMENSION_GET_SUCCESS,
      payload: {
        sample: responses[0],
        dimensions: responses[1],
      },
    });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_DIMENSION_GET_ERROR, payload: e });
  }
};

export const updateSurveySampleById = (id, sample, dimensions, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_DIMENSION_UPDATE_PENDING });
    await Promise.all([
      updateSurveySampleByIdApi(id, sample, jwt),
      updateSurveyDimensionByIdApi(id, dimensions, jwt),
    ]);
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_DIMENSION_UPDATE_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_DIMENSION_UPDATE_ERROR, payload: e });
  }
};

export const autoSavingSurveySample = () => async (dispatch) => {
  dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_DIMENSION_AUTO_SAVING });
};

export const autoSavedSurveySample = () => async (dispatch) => {
  dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_DIMENSION_AUTO_SAVED });
};
