import { prepareSectionMetadata } from '../../../actions/helpers/section.helper';
import { createSection, updateSection } from '../../../actions/section.action';

const handleSurveySectionSave = (
  sectionInfo,
  selectedSurveySectionData,
  currentSurvey,
  storeJwt
) => (setSelectedSurveySectionData, dispatch) => {
  if (selectedSurveySectionData.Id === -1) {
    dispatch(
      createSection(
        {
          ...selectedSurveySectionData.Data,
          name: sectionInfo.title,
        },
        storeJwt
      )
    );
  } else {
    dispatch(
      updateSection(
        selectedSurveySectionData.Id,
        {
          ...selectedSurveySectionData.Data,
          name: sectionInfo.title,
        },
        selectedSurveySectionData.Data,
        currentSurvey,
        storeJwt
      )
    );
  }

  const finalMetadata = prepareSectionMetadata(
    {
      ...selectedSurveySectionData.Data,
      name: sectionInfo.title,
    },
    selectedSurveySectionData.Data,
    currentSurvey
  );

  setSelectedSurveySectionData({
    ...selectedSurveySectionData,
    Data: finalMetadata,
  });
};

export default handleSurveySectionSave;
