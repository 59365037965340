import React, { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { sendMail } from '../../api/sendMail';
import { createUserApi } from '../../api/users';
import types from '../../types/index';
import Spinner from '../Spinner';
import RequestMessage from './RequestMessage';
import './style.css';

const SITE_KEY = '6LeUE_AUAAAAAOpEfq_x1P_fRWii-Nyn2hd4K3ME';

const SignUpForm = () => {
  const [validated, setValidated] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const jwt = useSelector((state) => state.session.jwt);

  const handleReset = () => {
    setSuccess(false);
    setError(false);
    setValidated(false);
  };

  const endMailRequest = (type) => {
    switch (type) {
      case types.email.success: {
        setSuccess(true);
        setIsSending(false);
        break;
      }
      case types.email.error: {
        setIsSending(false);
        setError(true);
        break;
      }
      default:
    }
  };

  const validateAndSend = async (payload) => {
    setIsSending(true);
    try {
      const token = await window.grecaptcha.execute(SITE_KEY, { action: 'login' });
      if (token) {
        const createUser = {
          ...payload,
          email: payload.orgEmail,
          username: payload.orgEmail,
          status: 'ACTIVE',
          role: 'NATIONAL_USER',
          state: '',
          lastLogin: new Date(),
        };
        delete createUser.title;
        delete createUser.orgEmail;
        delete createUser.heardAboutFrom;
        await createUserApi(createUser, jwt);
        await sendMail(token, jwt, payload, endMailRequest);
      }
    } catch (e) {
      setIsSending(false);
      setError(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const { elements } = form;
      const payload = {};
      Object.keys(elements).forEach((elKey) => {
        const el = elements[elKey];
        if (el.name && el.value) {
          const key = el.name.replace(/[^a-zA-Z0-9]/g, '');
          const { value } = el;
          payload[key] = value;
        }
      });
      validateAndSend(payload);
    }
    setValidated(true);
  };

  useEffect(() => {
    const loadScriptByURL = (id, url) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        document.body.appendChild(script);
      }
    };

    // load the script by passing the URL
    loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`);
  }, []);

  if (success) return <RequestMessage reset={handleReset} type={types.email.success} />;
  if (error) return <RequestMessage reset={handleReset} type={types.email.error} />;

  return (
    <Container className="bg-white p-4 m-0">
      {isSending && (
        <div className="form-spinner">
          <Spinner size="lg" />
        </div>
      )}
      <div className="Login-form-header">
        <span>Already have an account?</span>
        <a href={`/login${window.location.search}`} className="Login-btn">
          Login
        </a>
      </div>
      <div className="Login-form-title">
        Get a free account
        <span>* Required</span>
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
        <Form.Group>
          <Form.Control
            required
            type="text"
            placeholder="First name*"
            name="firstName"
            size="md"
            className="mb-2"
            maxLength="100"
            pattern="^[a-zA-Z\-]+$"
          />
          <Form.Control.Feedback type="invalid">
            Complete field or remove numbers and special characters{' '}
            {'(¡!"#$%&/()=¿?¡<>/\\´+{}[]_.,:;)'}.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            required
            type="text"
            placeholder="Last name*"
            name="lastName"
            size="md"
            className="mb-2"
            maxLength="100"
            pattern="^[a-zA-Z\-]+$"
          />
          <Form.Control.Feedback type="invalid">
            Complete field or remove numbers and special characters{' '}
            {'(¡!"#$%&/()=¿?¡<>/\\´+{}[]_.,:;)'}.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            required
            type="email"
            placeholder="Your Email*"
            name="orgEmail"
            size="md"
            className="mb-2"
            maxLength="100"
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Institution"
            name="institution"
            size="md"
            className="mb-2"
            maxLength="100"
            pattern="^[a-zA-Z0-9\s]*$"
          />
          <Form.Control.Feedback type="invalid">
            Complete field or remove special characters {'(¡!"#$%&/()=¿?¡<>/\\´+{}[]_.,:;)'}.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Title"
            name="title"
            size="md"
            className="mb-2"
            maxLength="100"
            pattern="^[a-zA-Z0-9\s]*$"
          />
          <Form.Control.Feedback type="invalid">
            Complete field or remove special characters {'(¡!"#$%&/()=¿?¡<>/\\´+{}[]_.,:;)'}.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="Login-form-selector">
          <Form.Label>How did you hear about Bento?</Form.Label>
          <Form.Control
            as="select"
            placeholder="How did you hear about Bento?"
            name="heardAboutFrom"
            size="md"
            className="mb-2"
          >
            <option value="">Select</option>
            <option value="From a colleague at my organization">
              From a colleague at my organization
            </option>
            <option value="From a contact at another organization">
              From a contact at another organization
            </option>
            <option value="From a communication from the RAND Corporation">
              From a communication from the RAND Corporation
            </option>
            <option value="Conference/webinar/online event">Conference/webinar/online event</option>
            <option value="From an outreach email">From an outreach email</option>
            <option value="From a search engine">From a search engine</option>
            <option value="Social media">Social media</option>
            <option value="Other">Other</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className="Login-form-selector">
          <Form.Label>Age Verification*</Form.Label>
          <Form.Check required type="checkbox" label="I am at least 14 years old" />
        </Form.Group>

        <Form.Group>
          <div className="Login-button-container">
            <Button type="submit" className="bento-btn">
              <span className="button-text">Get account</span>
            </Button>
          </div>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default SignUpForm;
