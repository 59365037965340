export const prepareSectionMetadata = (metadata, oldMetadata, survey) => {
  if (survey?.type && survey?.subType && survey?.year) {
    const overrideCode = `${
      survey.year
    }#${survey.type.toUpperCase()}#${survey.subType.toUpperCase()}`;
    const newSectionMetadata = {
      ...oldMetadata,
      overrides: {
        ...oldMetadata?.overrides,
        [overrideCode]: {
          ...oldMetadata?.overrides?.[overrideCode],
        },
      },
      name: metadata?.name,
    };

    return newSectionMetadata;
  }

  return metadata;
};

export const getSectionNameByCodeAndSurvey = (code, survey, sections) => {
  const overrideCode =
    survey && survey.type && survey.subType && survey.year
      ? `${survey.year}#${survey.type.toUpperCase()}#${survey.subType.toUpperCase()}`
      : null;

  const findSection = (section) =>
    section.Data && section.Data.code.toLowerCase() === code.toLowerCase();

  const section = sections.find(findSection);
  const overrideSection =
    overrideCode &&
    sections.find(
      (s) =>
        s.Data &&
        s.Data.overrides &&
        s.Data.overrides[overrideCode] &&
        s.Data.overrides[overrideCode]?.code?.toLowerCase() === code?.toLowerCase()
    );

  if (overrideSection) {
    return overrideSection.Data.overrides[overrideCode].name;
  }
  return section ? section.Data.name : code;
};
