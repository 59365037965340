/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { createRef, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import shortid from 'shortid';
import { getSurveySampleById } from '../../../../actions/survey.sample.action';
import { getSurveyQuestionBySurveyIdSectionAndQuestionId } from '../../../../actions/survey.action';
import Chart from '../../../../Components/Chart';
import { getSurveyQuestionsCategories } from '../../../../actions/survey.details.action';

const SurveyQuestion = ({ question, onSelectedQuestion, isSelected, sectionId }) => {
  const dispatch = useDispatch();
  const storeJwt = useSelector((state) => state.session.jwt);
  const { surveyQuestion } = useSelector((state) => state.surveys);
  const { visualizations, questionsCategories } = useSelector((state) => state.surveyDetails);
  const [currentQuestion, setCurrentQuestion] = useState(question);
  const [categories, setCategories] = useState([]);
  const handleQuestionClick = () => {
    if (isSelected) return;
    onSelectedQuestion(question.id);
    dispatch(getSurveySampleById(question.id, storeJwt));
    dispatch(
      getSurveyQuestionBySurveyIdSectionAndQuestionId(
        visualizations.contextId,
        sectionId,
        question.id,
        visualizations,
        storeJwt
      )
    );
    const questionId = question.relatedQuestionId || question.id;
    dispatch(getSurveyQuestionsCategories(questionId, storeJwt));
  };

  const updateChartType = (currJob, chartName) => {
    const updatedVisu = { ...visualizations };
    const defaultSettings = {
      benchmarks: {
        state: false,
        national: false,
      },
      showPrincipalResponses: false,
    };
    updatedVisu.details.chartSettings.chartSettings[currJob.id] = {
      ...(updatedVisu?.details?.chartSettings?.chartSettings[currJob.id] || defaultSettings),
      selectedChartType: chartName,
    };
  };

  const updateBenchmark = (currJob, benchmarks) => {
    const updatedVisu = { ...visualizations };
    updatedVisu.details.chartSettings.chartSettings[currJob.id] = {
      benchmarks,
      showPrincipalResponses: false,
      selectedChartType:
        updatedVisu?.details?.chartSettings?.chartSettings[currJob.id]?.selectedChartType ||
        currJob?.defaultVisualizationType,
    };
  };
  useEffect(() => {
    if (surveyQuestion && isSelected) {
      setCurrentQuestion(surveyQuestion);
      setCategories(questionsCategories);
    } else {
      setCurrentQuestion(question);
      setCategories([]);
    }
  }, [
    surveyQuestion,
    setCurrentQuestion,
    question,
    setCategories,
    questionsCategories,
    isSelected,
  ]);
  return (
    <Row>
      <Col onClick={handleQuestionClick}>
        {currentQuestion && (
          <Chart
            key={shortid.generate()}
            currentJob={currentQuestion}
            visualization={visualizations || {}}
            currentCategory={categories?.find((qc) => qc?.key_id === currentQuestion?.id)}
            questionsCategories={categories}
            isRelated={!!currentQuestion.relatedQuestionId}
            spaceAdded={false}
            updateChartType={updateChartType}
            updateBenchmark={updateBenchmark}
            onExport={() => {}}
            ref={createRef()}
          />
        )}
      </Col>
    </Row>
  );
};

export default SurveyQuestion;
