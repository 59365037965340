import { openModal } from '../../../../actions/modal.action';

const SampleDimension404Dialog = (dispatch) => {
  openModal({
    className: 'modal-alert-width',
    button1Text: 'Close',
    button1Action: 'closeModal',
    imageSrc: '/art-error-message.svg',
    body: 'ERROR: The current question has not sample or dimension in the database.',
  })(dispatch);
};

export default SampleDimension404Dialog;
