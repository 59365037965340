import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Col, Row, Button } from 'react-bootstrap';

const MoeCalculationInProgressModal = () => {
  const [show, setShow] = useState(false);
  const { calculatingMoe } = useSelector((state) => state.surveyDetails);

  const closeModal = () => {
    setShow(false);
  };
  useEffect(() => {
    setShow(calculatingMoe);
  }, [calculatingMoe]);
  return (
    <Modal centered show={show} onHide={closeModal} backdrop="static">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p>Calculating margin of error.</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" size="xl" onClick={closeModal}>
          CANCEL
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MoeCalculationInProgressModal;
