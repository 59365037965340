/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SurveyQuestion from '../SurveyQuestion';
import SurveyInformationSection from '../SurveyInformationSection';
import './styles.css';

const SurveySections = ({
  sectionName,
  surveyQuestions,
  onEditModeClick,
  onQuestionClick,
  isEditSection,
  selectedQuestionId,
}) => {
  const [isOpen, setIsOpen] = useState(isEditSection);
  const { loading } = useSelector((state) => state.surveyDetails);
  const { loading: sampleLoading } = useSelector((state) => state.surveySample);
  useEffect(() => {
    setIsOpen(isEditSection);
  }, [isEditSection, setIsOpen]);
  return (
    <>
      {(loading || sampleLoading) && (
        <div className="loading-container">
          <div className="content">
            <Spinner animation="border" role="status" />
            Updating preview with your changes
          </div>
        </div>
      )}
      {!loading && (
        <Card className="survey-info-card bg-gray">
          <Card.Header className="survey-info-card-header" onClick={() => setIsOpen(!isOpen)}>
            {sectionName}
            <span>
              {isOpen && <FontAwesomeIcon size="lg" className="ml-1 mr-5" icon={faSortDown} />}
              {!isOpen && <FontAwesomeIcon size="lg" className="ml-1 mr-5" icon={faSortUp} />}
            </span>
          </Card.Header>
          {isOpen && (
            <SurveyInformationSection sectionName={sectionName} onEditModeClick={onEditModeClick} />
          )}
          {surveyQuestions?.questions?.map((question) => (
            <SurveyQuestion
              key={question?.id}
              question={question}
              onSelectedQuestion={(id) => {
                onQuestionClick();
                onEditModeClick(id, 's-type-question');
              }}
              sectionId={surveyQuestions.id}
              isSelected={question.id === selectedQuestionId}
            />
          ))}
        </Card>
      )}
    </>
  );
};

SurveySections.propTypes = {
  sectionName: PropTypes.string.isRequired,
  onEditModeClick: PropTypes.func.isRequired,
  onQuestionClick: PropTypes.func.isRequired,
  isEditSection: PropTypes.bool.isRequired,
  surveyQuestions: PropTypes.instanceOf(Object).isRequired,
};

SurveySections.defaultProps = {};

export default SurveySections;
