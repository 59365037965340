import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import types from '../../types';
import MessageModal from './MessageModal';

import './style.css';

const CustomModal = () => {
  const modalProps = useSelector((state) => state.modal.modalProps);
  const dispatch = useDispatch();

  return (
    <Modal
      centered
      show={modalProps.open}
      onHide={() => dispatch({ type: types.reducerTypes.HIDE_MODAL })}
      className={`CustomModal ${modalProps.className}`}
      backdrop="static"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <MessageModal modalProps={modalProps} />
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
