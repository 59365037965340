export const openModal = (modalProps = {}) => (dispatch) => {
  dispatch({
    type: 'SHOW_MODAL',
    modalProps: { ...modalProps, open: true },
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: 'HIDE_MODAL',
  });
};
