import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { openModal } from '../../actions/modal.action';
import modalMessages from '../../messages/modal.messages';
import Types from '../../types';
import Spinner from '../Spinner';

import {
  createDraftSurvey,
  publishSurvey,
  unpublishSurvey,
  updateSurveyCustomNSize,
  updateSurveyThresholdCustomNSize,
} from '../../actions/survey.action';

import { selectedSurvey } from '../../actions/wizard.action';
import { ENABLE_SURVEY_WIZARD } from '../../config/appConfig';

const SurveyActions = ({
  id,
  isPublished,
  isDraft,
  spinner,
  isCustomNSize,
  minRespondents,
  hasChildren,
  parentId,
}) => {
  const dispatch = useDispatch();
  const [showEditThreshold, setShowEditThreshold] = useState(false);
  const jwt = useSelector((state) => state.session.jwt);
  const [localCustomNSize, setLocalCustomNSize] = useState(isCustomNSize);
  const [validated, setValidated] = useState(false);
  const [threshold, setThreshold] = useState(minRespondents);

  const publishSurveyHandler = () => {
    dispatch(publishSurvey(id, jwt));
  };

  const unpublishSurveyHandler = () => {
    dispatch(unpublishSurvey(id, jwt));
  };

  const convertDraftIntoSurveyHandler = () => {
    dispatch(selectedSurvey(id));
    dispatch(openModal(modalMessages('draftIntoMainSurvey')));
  };

  const createDraftSurveyHandler = () => {
    dispatch(createDraftSurvey(id, jwt));
  };

  const deleteDraftSurveyHandler = () => {
    dispatch(selectedSurvey(id, isDraft, parentId));
    dispatch(openModal(modalMessages('deleteDraftSurvey')));
  };

  const updateCustomNSize = () => {
    setLocalCustomNSize(!localCustomNSize);
    dispatch(updateSurveyCustomNSize(id, !localCustomNSize, jwt));
  };

  const handleToggleEditThreshold = () => setShowEditThreshold(!showEditThreshold);

  const handleSaveThresholdCustomNSize = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(updateSurveyThresholdCustomNSize(id, threshold, jwt));
    }
    handleToggleEditThreshold();
  };

  const handleThresholdChange = (e) => setThreshold(e.target.value);

  return (
    <>
      <div className="Card-actions">
        <ul className="Card-actions-list">
          <li>
            <span className="Card-link disabled" href="https://google.com">
              <span className="Card-action-icon">
                <img src="/manage-users.svg" alt="Manage Users" />
              </span>
              <span className="Card-action-name">Manage users</span>
            </span>
          </li>
          {!isPublished && ENABLE_SURVEY_WIZARD && (
            <li>
              <Link className="Card-link" to={`/admin/survey/edit/${id}`}>
                <span className="Card-action-icon">
                  <img src="/edit-icon.svg" alt="Edit Icon" />
                </span>
                <span className="Card-action-name">Edit</span>
              </Link>
            </li>
          )}
          <li>
            <button
              type="button"
              className="Card-link"
              onClick={handleToggleEditThreshold}
              disabled={spinner === Types.spinnerTypes.publishSurvey}
            >
              <span className="Card-action-icon">
                <img src="/edit-icon.svg" alt="Edit Icon" />
              </span>
              <span className="Card-action-name">Custom threshold for N-size</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              className="Card-link"
              onClick={updateCustomNSize}
              disabled={spinner === Types.spinnerTypes.publishSurvey}
            >
              <span className="Card-action-icon">
                <FontAwesomeIcon size="xs" icon={!localCustomNSize ? faCheckSquare : faSquare} />
              </span>
              <span className="Card-action-name">Display N-Size as a range</span>
            </button>
          </li>
          {isPublished && !hasChildren && (
            <li>
              <button
                type="button"
                className="Card-link"
                onClick={unpublishSurveyHandler}
                disabled={spinner === Types.spinnerTypes.publishSurvey}
              >
                <span className="Card-action-icon">
                  {spinner === Types.spinnerTypes.publishSurvey && <Spinner />}
                  {!(spinner === Types.spinnerTypes.publishSurvey) && (
                    <img src="/unpublish-icon.svg" alt="Unpublish Survey" />
                  )}
                </span>
                <span className="Card-action-name">Unpublish</span>
              </button>
            </li>
          )}
          {!isPublished && !isDraft && (
            <li>
              <button
                type="button"
                className="Card-link"
                onClick={publishSurveyHandler}
                disabled={spinner === Types.spinnerTypes.publishSurvey}
              >
                <span className="Card-action-icon">
                  {spinner === Types.spinnerTypes.publishSurvey && <Spinner />}
                  {!(spinner === Types.spinnerTypes.publishSurvey) && (
                    <img src="/publish-icon.svg" alt="Publish Survey" />
                  )}
                </span>
                <span className="Card-action-name">Publish</span>
              </button>
            </li>
          )}
          {isPublished && (
            <li>
              <a className="Card-link disabled" href="https://google.com">
                <span className="Card-action-icon">
                  <img src="/view-icon.svg" alt="View Survey" />
                </span>
                <span className="Card-action-name">View</span>
              </a>
            </li>
          )}
          {!isPublished && (
            <li>
              <a className="Card-link disabled" href="#.">
                <span className="Card-action-icon">
                  <img src="/view-icon.svg" alt="Preview Survey" />
                </span>
                <span className="Card-action-name">Preview</span>
              </a>
            </li>
          )}
          {isPublished && (
            <li>
              <button
                className="Card-link"
                type="button"
                onClick={createDraftSurveyHandler}
                disabled={spinner === Types.spinnerTypes.createDraftSurvey}
              >
                <span className="Card-action-icon">
                  {spinner === Types.spinnerTypes.createDraftSurvey && <Spinner />}

                  {!(spinner === Types.spinnerTypes.createDraftSurvey) && (
                    <img src="/create-draft-icon.svg" alt="Create Survey" />
                  )}
                </span>
                <span className="Card-action-name">Create draft survey</span>
              </button>
            </li>
          )}
          {!isPublished && isDraft && (
            <li>
              <button
                type="button"
                className="Card-link"
                onClick={convertDraftIntoSurveyHandler}
                disabled={spinner === Types.spinnerTypes.convertDraftIntoSurvey}
              >
                <span className="Card-action-icon">
                  {spinner === Types.spinnerTypes.convertDraftIntoSurvey && <Spinner />}
                  {!(spinner === Types.spinnerTypes.convertDraftIntoSurvey) && (
                    <img src="/convert-icon.svg" alt="Convert to main survey" />
                  )}
                </span>
                <span className="Card-action-name">Convert to main survey</span>
              </button>
            </li>
          )}
          {(!isPublished || isDraft) && (
            <li>
              <button
                type="button"
                className="Card-link"
                onClick={deleteDraftSurveyHandler}
                disabled={spinner === Types.spinnerTypes.deleteDraftSurvey}
              >
                <span className="Card-action-icon">
                  {spinner === Types.spinnerTypes.deleteDraftSurvey && <Spinner />}

                  {!(spinner === Types.spinnerTypes.deleteDraftSurvey) && (
                    <img src="/delete-icon.svg" alt="Delete Survey" />
                  )}
                </span>
                <span className="Card-action-name">Delete</span>
              </button>
            </li>
          )}
          {!isPublished && isDraft && (
            <li>
              <Link className="Card-link" to={`/admin/survey/replace/${id}`}>
                <span className="Card-action-icon">
                  <img src="/replace-icon.svg" alt="Replace data set" />
                </span>
                <span className="Card-action-name">Replace data set</span>
              </Link>
            </li>
          )}
        </ul>
        <Modal
          centered
          show={showEditThreshold}
          onHide={handleToggleEditThreshold}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <h3>Edit Threshold</h3>
          </Modal.Header>
          <Form
            noValidate
            validated={validated}
            className="text-left"
            onSubmit={handleSaveThresholdCustomNSize}
          >
            <Modal.Body>
              <Form.Row>
                <Form.Group as={Col} md="6" controlId="validationThreshold">
                  <Form.Label>Threshold *</Form.Label>
                  <Form.Control
                    defaultValue={threshold}
                    placeholder="Threshold custom N-Size"
                    required
                    onChange={handleThresholdChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please complete Threshold.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" size="xl" onClick={handleToggleEditThreshold}>
                CANCEL
              </Button>
              <Button type="submit" variant="dark" size="xl">
                SAVE
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </>
  );
};

SurveyActions.propTypes = {
  isPublished: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  isDraft: PropTypes.bool.isRequired,
  spinner: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  isCustomNSize: PropTypes.bool.isRequired,
  minRespondents: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  hasChildren: PropTypes.bool.isRequired,
  parentId: PropTypes.number.isRequired,
};

export default SurveyActions;
